@if (error()) {
  <app-info-block variety="error">{{
    error() || "Неизвестная ошибка"
  }}</app-info-block>
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && disabledAnnouncements().length < 1"
  emptyDescription="Список завершенных объявлений пуст"
>
  <div class="user-contacts-announcements-list">
    @for (announcement of disabledAnnouncements(); track announcement.slug) {
      <app-announcement-card
        source="user-disable"
        [announcement]="announcement"
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [isFavouritesLoading]="isFavouritesLoading()"
        [showFavourite]="announcement.is_favorite"
        class="user-announcement-disabled"
      />
    }
  </div>

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
