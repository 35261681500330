@if (organizationData.isLoading()) {
  <div class="loader">
    <app-loader />
  </div>
} @else {
  <form
    name="formChangeOrganizationData"
    #formChangeOrganizationData="ngForm"
    (ngSubmit)="handleSubmit()"
    class="profile-form"
  >
    <app-info-block *ngIf="!!apiError()" variety="error">{{
      apiError() || "Неизвестная ошибка"
    }}</app-info-block>

    <hr class="top" />
    <div class="profile-form-item">
      <div class="description">
        <div class="label">
          Наименование организации
          <sup class="required">*</sup>
        </div>
        <div *ngIf="!!name.invalid && !!name.touched" class="error">
          Обязательное поле (от 1 до 255 символов)
        </div>
      </div>
      <app-input-label
        id="name"
        name="name"
        #name="ngModel"
        [(ngModel)]="organizationChangeForm.name"
        placeholder="Наименование организации"
        [disabled]="loading()"
        [required]="true"
        [maxlength]="255"
        [minlength]="4"
        [error]="!!name.invalid && !!name.touched"
        class="item-input"
      />
    </div>

    <hr />
    <div class="profile-form-item">
      <div class="description">
        <div class="label">ИНН</div>
        <div *ngIf="!!inn.invalid && !!inn.touched" class="error">
          10 или 12 цифр
        </div>
      </div>
      <app-input-label
        id="inn"
        name="inn"
        #inn="ngModel"
        [(ngModel)]="organizationChangeForm.inn"
        placeholder=""
        [disabled]="loading()"
        inputMask="0000000000||000000000000"
        inputMode="numeric"
        [pattern]="innPattern"
        [error]="!!inn.invalid && !!inn.touched"
        class="item-input"
      />
    </div>

    <hr />
    <div class="profile-form-item">
      <div class="description">
        <div class="label">
          Юридический адрес
          <sup class="required">*</sup>
        </div>
        <div class="description-text">
          Адрес будет автоматически заполняться при создании объявления
        </div>
        <div *ngIf="showErrorAddress() && errorAddress()" class="error">
          {{ errorAddress() }}
        </div>
      </div>
      <app-input-address
        id="address_data"
        [textAreaMode]="true"
        [loading]="loadingAddressList()"
        [addressesRequested]="addressesRequested()"
        [initialAddress]="initialAddress()"
        [addressList]="addressList()"
        (blurAddress)="handleBlurAddress()"
        (address)="onSearchAddress($event)"
        [error]="errorAddress()"
        [showError]="showErrorAddress()"
        class="item-input"
      />
    </div>

    <hr />
    <app-button
      variety="secondary"
      type="submit"
      [disabled]="!!formChangeOrganizationData.invalid || !!errorAddress()"
      >Сохранить
    </app-button>
  </form>
}
