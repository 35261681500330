<app-external-layout>
  <div class="full-screen-form">
    @if (!loading()) {
      @if (!error()) {
        @if (wasAuthenticated()) {
          <div class="title mb-32">Успешное подтверждение почты</div>
          <app-info-block variety="success"
            >Вы подтвердили Email в сервисе
          </app-info-block>
        } @else {
          <div class="title mb-32">Успешная регистрация</div>
          <app-info-block variety="success"
            >Вы успешно зарегистрировались в сервисе
          </app-info-block>
        }
        <app-link link="/" description="Перейти на главную" />
      } @else {
        <div class="title mb-32">Ошибка подтверждения регистрации</div>
        <app-info-block variety="error"
          >{{ error() || "Неизвестная ошибка" }}
        </app-info-block>
        @if (authService.isAuth()) {
          <app-link link="/" description="Перейти на главную" />
        } @else {
          <div class="link-block">
            Есть аккаунт?
            <app-link [link]="authRoute" description="Войти" />
          </div>
        }
      }
    } @else {
      <app-loader class="registration-loader" />
    }
  </div>
</app-external-layout>
