@if (error()) {
  <app-info-block variety="error">{{
    error() || "Неизвестная ошибка"
  }}</app-info-block>
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && favouritesAnnouncements().length < 1"
  emptyDescription="Список избранных объявлений пуст"
>
  <div class="favourites-announcements-list">
    @for (
      announcement of favouritesAnnouncements();
      let index = $index;
      track announcement.slug
    ) {
      <app-announcement-card
        source="favourites"
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [announcement]="announcement"
        [isFavouritesLoading]="isFavouritesLoading()"
        [class.disabled]="isFavouritesLoading()"
      />
    }
  </div>

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
