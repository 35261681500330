import { Component } from '@angular/core';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';

@Component({
  selector: 'app-confirm-email',
  imports: [InfoBlockComponent, ButtonLinkComponent],
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss',
})
export class ConfirmEmailComponent {}
