import {
  Component,
  OnInit,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';

import { MobileProfileComponent } from '../mobile-profile/mobile-profile.component';
import {
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_PROFILE,
  ROUTE_PROFILE_PRICE_EDIT,
} from '@settings/routes';

import { UIService } from '@services/ui.service';
import { ProfileService } from '@services/profile.service';

@Component({
  selector: 'app-main-profile',
  imports: [MobileProfileComponent],
  templateUrl: './main-profile.component.html',
  styleUrl: './main-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainProfileComponent implements OnInit {
  uiService = inject(UIService);
  router = inject(Router);
  profileService = inject(ProfileService);

  isMobile = this.uiService.isMobile;

  ngOnInit() {
    if (!this.isMobile()) {
      if (this.profileService.isRecycler()) {
        void this.router.navigate([
          '/',
          ROUTE_PROFILE,
          ROUTE_PROFILE_PRICE_EDIT,
        ]);
      } else {
        void this.router.navigate(['/', ROUTE_PROFILE, ROUTE_MY_ANNOUNCEMENTS]);
      }
    }
  }
}
