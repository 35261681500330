@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title>Фильтры объявлений</app-title>
  </app-mobile-header>
} @else {
  <app-title class="priority-info-title">Фильтры объявлений</app-title>
}

<div class="priority-info-block">
  <app-info-block [withoutMargin]="true" [thinText]="true" class="priority-info"
    >То, что вы выберете здесь, будет автоматически фильтроваться у вас в доске
    объявлений</app-info-block
  >
  <app-button
    variety="secondary"
    (pressed)="openDefaultDialog()"
    [disabled]="defaultLoading() || regionLoading() || categoryLoading()"
    >По умолчанию</app-button
  >
</div>

<div class="priority-lists-block">
  <div class="priority-list">
    <div class="list-title">Интересующие категории объявлений</div>
    <div class="categories-types">
      <button
        type="button"
        class="type left"
        [class.active]="announcementType() === 'sell'"
        (click)="changingTypeCategories('sell')"
      >
        Продажа
      </button>
      <button
        type="button"
        class="type right"
        [class.active]="announcementType() === 'buy'"
        (click)="changingTypeCategories('buy')"
      >
        Покупка
      </button>
    </div>

    <hr class="priority-hr mt-24 mb-0" />
    <div class="priority-selected-list">
      @for (category of categoryList(); track category.code) {
        <!-- В категории есть культуры -->
        <mat-accordion *ngIf="category.cereals && category.cereals.length > 0">
          <mat-expansion-panel class="categories-panel" expanded="false">
            <mat-expansion-panel-header>
              <div class="item">
                <mat-checkbox
                  [checked]="category.status === 'selected'"
                  [indeterminate]="category.status === 'partly'"
                  (click)="
                    toggleCategoriesTreeEntity($event, {
                      categoryId: category.code,
                    })
                  "
                  [disabled]="categoryLoading() || defaultLoading()"
                  class="priority"
                >
                  <div class="checkbox-label">{{ category.label }}</div>
                </mat-checkbox>
              </div>
            </mat-expansion-panel-header>

            <hr class="priority-hr mb-0" />

            @if (category.cereals) {
              @for (
                cereal of category.cereals;
                let index = $index;
                track cereal.id
              ) {
                <!-- В культуре есть классификации -->
                <mat-accordion
                  *ngIf="
                    cereal.classifications && cereal.classifications.length > 0
                  "
                >
                  <mat-expansion-panel
                    class="cereals-panel"
                    [class.last-cereal]="index === category.cereals.length - 1"
                    expanded="false"
                  >
                    <mat-expansion-panel-header class="cereals-panel-header">
                      <div class="item cereal">
                        <mat-checkbox
                          [checked]="cereal.status === 'selected'"
                          [indeterminate]="cereal.status === 'partly'"
                          (click)="
                            toggleCategoriesTreeEntity($event, {
                              categoryId: category.code,
                              cerealId: cereal.id,
                            })
                          "
                          [disabled]="categoryLoading() || defaultLoading()"
                          class="priority"
                        >
                          <div class="checkbox-label">
                            {{ cereal.name }}
                          </div>
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel-header>

                    @if (cereal.classifications) {
                      @for (
                        classification of cereal.classifications;
                        track classification.id
                      ) {
                        <div class="item classification">
                          <mat-checkbox
                            [checked]="classification.status === 'selected'"
                            [indeterminate]="classification.status === 'partly'"
                            (click)="
                              toggleCategoriesTreeEntity($event, {
                                categoryId: category.code,
                                cerealId: cereal.id,
                                classificationId: classification.id,
                              })
                            "
                            [disabled]="categoryLoading() || defaultLoading()"
                            class="priority"
                          >
                            <div class="checkbox-label">
                              {{ classification.name }}
                            </div>
                          </mat-checkbox>
                        </div>
                      }
                    }
                  </mat-expansion-panel>
                </mat-accordion>

                <!-- В культуре нет классификаций -->
                <div
                  *ngIf="
                    !cereal.classifications || cereal.classifications.length < 1
                  "
                  class="item-wrapper cereal"
                >
                  <div class="item">
                    <mat-checkbox
                      [checked]="cereal.status === 'selected'"
                      [indeterminate]="cereal.status === 'partly'"
                      (click)="
                        toggleCategoriesTreeEntity($event, {
                          categoryId: category.code,
                          cerealId: cereal.id,
                        })
                      "
                      [disabled]="categoryLoading() || defaultLoading()"
                      class="priority"
                    >
                      <div class="checkbox-label">{{ cereal.name }}</div>
                    </mat-checkbox>
                  </div>
                </div>
              }
            }
          </mat-expansion-panel>
        </mat-accordion>

        <!-- В категории нет культур -->
        <div
          *ngIf="!category.cereals || category.cereals.length < 1"
          class="item-wrapper"
        >
          <div class="item">
            <mat-checkbox
              [checked]="category.status === 'selected'"
              [indeterminate]="category.status === 'partly'"
              (click)="
                toggleCategoriesTreeEntity($event, {
                  categoryId: category.code,
                })
              "
              [disabled]="categoryLoading() || defaultLoading()"
              class="priority"
            >
              <div class="checkbox-label">{{ category.label }}</div>
            </mat-checkbox>
          </div>
        </div>
      }
    </div>
    <hr class="priority-hr mt-0" />
  </div>

  <div class="priority-list">
    <div class="list-title">Регионы</div>
    <hr class="priority-hr mt-24" />
    <mat-accordion>
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <div class="panel-title">
            Регионы: <span>{{ messageSelectedRegions() }}</span>
          </div>
        </mat-expansion-panel-header>

        <app-search
          #profileRegionSearch
          placeholder="Поиск по регионам"
          variety="priority"
          (searchText)="onRegionSearch($event)"
          class="sticky-search"
        />

        <div class="priority-selected-list regions">
          @if (filterRegionsList().length > 0) {
            <div class="item">
              <mat-checkbox
                [checked]="allRegions()"
                (change)="toggleAllRegions()"
                [disabled]="regionLoading() || defaultLoading()"
              >
                <div class="checkbox-label">Все регионы</div>
              </mat-checkbox>
            </div>
            @for (region of filterRegionsList(); track region.id) {
              <div class="item">
                <mat-checkbox
                  [checked]="region.is_selected"
                  (change)="toggleRegion(region.id, !region.is_selected)"
                  [disabled]="regionLoading() || defaultLoading()"
                >
                  <div class="checkbox-label">{{ region.name }}</div>
                </mat-checkbox>
              </div>
            }
          } @else {
            <div class="empty-list">Регионы не найдены</div>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <hr class="priority-hr" />
  </div>
</div>
