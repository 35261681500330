import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';
import { NgIf, ViewportScroller } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';

import { environment } from '@environments/environment';

import { ButtonComponent } from '@components/ui/button/button.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { InputAddressComponent } from '@components/ui/input-address/input-address.component';
import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ProfileService } from '@services/profile.service';
import { NotificationService } from '@services/notification.service';
import { TAddresses } from '@models/mapTypes';
import {
  TChangeOrganizationRequest,
  TOrganizationData,
} from '@models/profileTypes';
import { INN_PATTERN } from '@settings/validationSettings';
import { MapService } from '@services/map.service';
import { AddressStringPipe } from '@pipes/address-string.pipe';
import { ROUTE_PROFILE, ROUTE_SETTINGS } from '@settings/routes';

@Component({
  selector: 'app-profile-organization',
  imports: [
    ButtonComponent,
    FormsModule,
    InfoBlockComponent,
    InputAddressComponent,
    InputLabelComponent,
    NgIf,
    ReactiveFormsModule,
    LoaderComponent,
  ],
  templateUrl: './profile-organization.component.html',
  styleUrl: './profile-organization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileOrganizationComponent {
  http = inject(HttpClient);
  profileService = inject(ProfileService);
  notificationService = inject(NotificationService);
  viewport = inject(ViewportScroller);
  mapService = inject(MapService);
  router = inject(Router);

  // Заполнение адреса
  initialAddress = signal<string>('');
  addressList = signal<TAddresses>([]);
  addressesRequested = signal<boolean>(false);
  loadingAddressList = signal(false);
  showErrorAddress = signal(false);
  errorAddress = signal('');

  innPattern = INN_PATTERN;

  profileData = this.profileService.profileData;
  loading = signal(false);
  apiError = signal<string | null>(null);

  organizationChangeForm: TChangeOrganizationRequest = {
    name: '',
    inn: '',
    address_data: null,
  };

  organizationData = rxResource({
    loader: () => {
      if (!this.profileService.isRecycler()) {
        void this.router.navigate(['/', ROUTE_PROFILE, ROUTE_SETTINGS]);
      }

      return this.http
        .get<TOrganizationData>(
          `${environment.getApiVersionUrl('user/organization')}`,
        )
        .pipe(
          tap((response) => {
            this.organizationChangeForm = {
              name: response.name || '',
              inn: response.inn || '',
              address_data: null,
            };

            if (response.legal_address) {
              this.initialAddress.set(response.legal_address);
            }
          }),
          catchError((error: TFormattedApiError) => {
            this.apiError.set(error.formattedErrorMessage);
            this.notificationService.error(error.formattedErrorMessage);
            return of(null);
          }),
        );
    },
  });

  // Запрос списка адресов и выбор адреса
  onSearchAddress(address: string) {
    if (address && address.trim().length >= 3) {
      this.loadingAddressList.set(true);

      this.mapService.findAddress(address).subscribe({
        next: (response) => {
          this.addressList.set(response);
          this.addressesRequested.set(true);

          this.loadingAddressList.set(false);

          const currentAddress = this.addressList().find(
            (item) =>
              new AddressStringPipe().transform(item).toLowerCase() ===
              address.toLowerCase(),
          );

          if (
            currentAddress &&
            (currentAddress.street || currentAddress.house_block_flat) &&
            currentAddress.lat &&
            currentAddress.lon
          ) {
            this.organizationChangeForm.address_data = {
              region: currentAddress.region,
              area: currentAddress.area || null,
              city: currentAddress.city || null,
              settlement: currentAddress.settlement || null,
              street: currentAddress.street || null,
              house_block_flat: currentAddress.house_block_flat || null,
              lat: currentAddress.lat,
              lon: currentAddress.lon,
            };

            this.errorAddress.set('');
          } else {
            this.organizationChangeForm.address_data = null;
            this.errorAddress.set('Укажите точный адрес');
          }
        },
        error: (error: TFormattedApiError) => {
          this.loadingAddressList.set(false);
          this.addressList.set([]);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      this.organizationChangeForm.address_data = null;
      this.addressList.set([]);
      this.addressesRequested.set(false);
      this.errorAddress.set('');
    }
  }

  handleBlurAddress() {
    this.showErrorAddress.set(true);
  }

  handleSubmit() {
    this.loading.set(true);

    const requestData = this.organizationChangeForm.address_data
      ? this.organizationChangeForm
      : {
          ...this.organizationChangeForm,
          address_data: null,
        };

    this.profileService.editOrganization(requestData).subscribe({
      next: () => {
        // this.profileService.setProfileData(response);
        this.loading.set(false);
        this.apiError.set(null);

        this.notificationService.success(
          'Данные организации успешно обновлены',
        );
      },
      error: (error: TFormattedApiError) => {
        this.loading.set(false);

        this.apiError.set(error.formattedErrorMessage);
        this.viewport.scrollToPosition([0, 0]);
      },
    });
  }
}
