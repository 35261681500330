import { TMenuItemsString } from '@models/commonTypes';
import {
  ROUTE_PROFILE,
  ROUTE_PROFILE_CONTACTS,
  ROUTE_PROFILE_ORGANIZATION,
  ROUTE_PROFILE_SAFETY,
  ROUTE_SETTINGS,
} from '@settings/routes';

export const settingsProfileMenuList = (
  isRecycler: boolean,
): TMenuItemsString => {
  const menu = [
    {
      id: 'settings-profile',
      link: `/${ROUTE_PROFILE}/${ROUTE_SETTINGS}`,
      name: 'Личные данные',
    },
    {
      id: 'settings-profile-contacts',
      link: ROUTE_PROFILE_CONTACTS,
      name: 'Контактные данные',
    },
    {
      id: 'settings-profile-safety',
      link: ROUTE_PROFILE_SAFETY,
      name: 'Безопасность',
    },
  ];

  if (isRecycler) {
    menu.splice(2, 0, {
      id: 'settings-organization',
      link: ROUTE_PROFILE_ORGANIZATION,
      name: 'Настройки организации',
    });
  }

  return menu;
};
