import { Component, inject, signal, effect, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ExternalLayoutComponent } from '@components/layouts/external-layout/external-layout.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { TTokens } from '@models/authTypes';
import { environment } from '@environments/environment';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_AUTH } from '@settings/routes';
import { STORAGE_REDIRECT_PATH } from '@settings/constants';

import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.sevice';
import { NotificationService } from '@services/notification.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-email-confirmation',
  imports: [
    ExternalLayoutComponent,
    InfoBlockComponent,
    LinkComponent,
    LoaderComponent,
  ],
  templateUrl: './email-confirmation.component.html',
  styleUrl: './email-confirmation.component.scss',
})
export class EmailConfirmationComponent {
  authService = inject(AuthService);
  storageService = inject(StorageService);
  notificationService = inject(NotificationService);
  platformId = inject(PLATFORM_ID);
  router = inject(Router);
  http = inject(HttpClient);
  route = inject(ActivatedRoute);

  loading = signal(true);
  error = signal<string | null>(null);
  wasAuthenticated = signal(false);

  authRoute = `/${ROUTE_AUTH}`;

  confirmToken = effect(() => {
    if (isPlatformBrowser(this.platformId)) {
      if (this.authService.isAuth() !== undefined) {
        const params = this.route.snapshot.queryParams;

        if (params['token']) {
          this.http
            .put<TTokens>(
              `${environment.getApiVersionUrl('auth/signup/confirm-email')}`,
              {
                token: params['token'],
                ...environment.getRequestData(),
              },
              {
                headers: {
                  skipBearerAuthorization: 'true',
                },
                withCredentials: true,
              },
            )
            .subscribe({
              next: (response) => {
                this.error.set(null);

                if (this.authService.isAuth()) {
                  this.wasAuthenticated.set(true);
                  // когда залогинился в систему
                  // возможно тут надо поработать с новыми токенами
                  this.authService.afterLogIn(response);
                  this.notificationService.success(
                    'Поздравляем, вы успешно подтвердили ваш Email в системе!',
                  );
                } else {
                  // когда не залогинен в систему
                  // Авторизация пользователя сразу после верификации
                  this.wasAuthenticated.set(false);
                  this.authService.afterLogIn(response);

                  // Редирект на главную или по сохраненному пути
                  const redirectPath = this.storageService.getItem<string>(
                    STORAGE_REDIRECT_PATH,
                  );

                  this.router.navigateByUrl(redirectPath || '/').then(() => {
                    if (redirectPath)
                      this.storageService.clearItem(STORAGE_REDIRECT_PATH);
                  });

                  this.notificationService.success(
                    'Поздравляем, вы успешно зарегистрированы в системе!',
                  );
                }

                this.loading.set(false);
              },

              error: (error: TFormattedApiError) => {
                this.error.set(error.formattedErrorMessage);
                this.loading.set(false);
              },
            });
        } else {
          this.error.set('Подтверждение регистрации невозможно');
          this.loading.set(false);
        }
      }
    }
  });
}
