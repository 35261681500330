@if (error()) {
  <app-info-block variety="error">{{
    error() || "Неизвестная ошибка"
  }}</app-info-block>
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && myAnnouncements().length < 1"
  emptyDescription="Список черновиков пуст"
>
  @for (
    announcement of myAnnouncements();
    let index = $index;
    track announcement.slug
  ) {
    <app-my-announcements-card
      variety="draft"
      [announcement]="announcement"
      [isLastCard]="myAnnouncements().length - 1 === index"
    />
  }

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
