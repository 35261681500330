import {
  ROUTE_FAVOURITES,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_OPEN_CONTACTS,
  ROUTE_PROFILE,
  ROUTE_PROFILE_PRICE_EDIT,
  ROUTE_PROFILE_PRIORITY,
  ROUTE_SETTINGS,
  // ROUTE_SUBSCRIPTION,
} from '@settings/routes';
import { TBaseTraderKind } from '@components/pages/registration/core/registrationTypes';

export const headerProfileMenuList = (userType: TBaseTraderKind) => {
  const menu = [
    {
      id: 'header-profile-menu-1',
      link: `/${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`,
      label: 'Мои объявления',
    },
    {
      id: 'header-profile-menu-priority',
      link: `/${ROUTE_PROFILE}/${ROUTE_PROFILE_PRIORITY}`,
      label: 'Фильтры объявлений',
    },
    {
      id: 'header-profile-menu-2',
      link: `/${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`,
      label: 'Избранное',
    },
    {
      id: 'header-profile-menu-3',
      link: `/${ROUTE_PROFILE}/${ROUTE_OPEN_CONTACTS}`,
      label: 'Открытые контакты',
    },
    // subscription пока скрываем раздел с подпиской
    // {
    //   id: 'header-profile-menu-4',
    //   link: `/${ROUTE_PROFILE}/${ROUTE_SUBSCRIPTION}`,
    //   label: 'Подписка',
    // },
    {
      id: 'header-profile-menu-5',
      link: `/${ROUTE_PROFILE}/${ROUTE_SETTINGS}`,
      label: 'Настройки',
    },
  ];
  if (userType === 'recycler') {
    menu.push({
      id: 'header-profile-menu-6',
      link: `/${ROUTE_PROFILE}/${ROUTE_PROFILE_PRICE_EDIT}`,
      label: 'Редактирование цен',
    });
  }
  return menu;
};
