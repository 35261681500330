import {
  Component,
  ChangeDetectionStrategy,
  signal,
  inject,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';

import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { RegistrationService } from '../../core/registration.service';
import { ButtonComponent } from '@components/ui/button/button.component';
import { INN_PATTERN } from '@settings/validationSettings';
import { ErrorComponent } from '@components/common/error/error.component';
import { InputAddressComponent } from '@components/ui/input-address/input-address.component';
import { TAddress, TAddresses } from '@models/mapTypes';
import { MapService } from '@services/map.service';
import { AddressStringPipe } from '@pipes/address-string.pipe';
import { TFormattedApiError } from '@utils/formattingApiError';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-set-organization',
  imports: [
    FormsModule,
    InputLabelComponent,
    ButtonComponent,
    MatRadioModule,
    ErrorComponent,
    NgIf,
    InputAddressComponent,
  ],
  templateUrl: './set-organization.component.html',
  styleUrl: './set-organization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetOrganizationComponent {
  error = this.registrationService.error;
  loading = this.registrationService.loading;

  mapService = inject(MapService);
  notificationService = inject(NotificationService);

  // Заполнение адреса
  initialAddress = signal<string>('');
  addressList = signal<TAddresses>([]);
  loadingAddressList = signal(false);
  addressesRequested = signal<boolean>(false);
  showErrorAddress = signal(false);
  errorAddress = signal('');
  address = signal<TAddress | null>(null);

  innPattern = INN_PATTERN;

  constructor(private registrationService: RegistrationService) {}

  setOrganization(setOrganizationForm: NgForm) {
    this.registrationService.setOrganizationData({
      ...setOrganizationForm.value,
      address_data: this.address(),
    });
  }

  // Запрос списка адресов и выбор адреса
  onSearchAddress(address: string) {
    if (address && address.trim().length >= 3) {
      this.loadingAddressList.set(true);

      this.mapService.findAddress(address).subscribe({
        next: (response) => {
          this.addressList.set(response);
          this.addressesRequested.set(true);

          this.loadingAddressList.set(false);

          const currentAddress = this.addressList().find(
            (item) =>
              new AddressStringPipe().transform(item).toLowerCase() ===
              address.toLowerCase(),
          );

          if (
            currentAddress &&
            (currentAddress.street || currentAddress.house_block_flat) &&
            currentAddress.lat &&
            currentAddress.lon
          ) {
            this.address.set({
              region: currentAddress.region,
              area: currentAddress.area || null,
              city: currentAddress.city || null,
              settlement: currentAddress.settlement || null,
              street: currentAddress.street || null,
              house_block_flat: currentAddress.house_block_flat || null,
              lat: currentAddress.lat,
              lon: currentAddress.lon,
            });

            this.errorAddress.set('');
          } else {
            this.address.set(null);
            this.errorAddress.set('Укажите точный адрес');
          }
        },
        error: (error: TFormattedApiError) => {
          this.loadingAddressList.set(false);
          this.addressList.set([]);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      // this.profileChangeForm.address_data = null;
      this.addressList.set([]);
      this.addressesRequested.set(false);
      this.errorAddress.set('');
    }
  }

  handleBlurAddress() {
    if (!this.address()) {
      this.errorAddress.set('Укажите точный адрес');
    }
    this.showErrorAddress.set(true);
  }
}
