import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  viewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { WA_WINDOW } from '@ng-web-apis/common';

import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_FAVOURITES,
  ROUTE_PROFILE,
  ROUTE_PROFILE_PRICE_EDIT,
  ROUTE_SEARCH,
} from '@settings/routes';
import { AuthService } from '@services/auth.service';
import { LogoLinkComponent } from '../logo-link/logo-link.component';
import { headerMenuList, headerMenuListOpen } from '@settings/headerMenuLists';
import { ButtonComponent } from '@components/ui/button/button.component';
import { SearchWithButtonComponent } from '@components/ui/search/search-with-button/search-with-button.component';
import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { SelectLocationComponent } from '@components/ui/select-location/select-location.component';
import { IconButtonLinkComponent } from '@components/ui/icon-button-link/icon-button-link.component';
import { ProfileMenuComponent } from '@components/common/profile-menu/profile-menu.component';
import { TMenuItems } from '@models/commonTypes';
import { MapLabelsService } from '@components/pages/map/map-labels.service';
import { ProfileService } from '@services/profile.service';

@Component({
  selector: 'app-header',
  imports: [
    MenuLinkComponent,
    RouterLink,
    ButtonComponent,
    ButtonLinkComponent,
    IconButtonLinkComponent,
    SearchWithButtonComponent,
    LogoLinkComponent,
    SelectLocationComponent,
    ProfileMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  search = viewChild<SearchWithButtonComponent>('headerSearch');

  router = inject(Router);
  profileService = inject(ProfileService);
  window = inject(WA_WINDOW);

  mapUrl = this.map.mapUrl;

  constructor(
    private authService: AuthService,
    private readonly map: MapLabelsService,
  ) {}

  isAuth = this.authService.isAuth;
  menuList = computed<TMenuItems>(() => {
    if (true === this.isAuth()) {
      return headerMenuList;
    }
    if (false === this.isAuth()) {
      return headerMenuListOpen;
    }

    return [];
  });

  authLink = `/${ROUTE_AUTH}`;
  favouritesLink = `/${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`;
  createAnnouncementLink = `/${ROUTE_ANNOUNCEMENT_CREATE}`;
  editPriceLink = `/${ROUTE_PROFILE}/${ROUTE_PROFILE_PRICE_EDIT}`;

  // Поиск по объявлениям
  searchAnnouncements(search: string) {
    void this.router.navigate(['/', ROUTE_SEARCH], {
      queryParams: { search: search || undefined },
    });

    this.search()?.clear();
  }
}
