import { NgIf, ViewportScroller } from '@angular/common';
import {
  Component,
  Inject,
  effect,
  inject,
  signal,
  viewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';

import { ConfirmationInformationComponent } from '@components/common/confirmation-information/confirmation-information.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import {
  TSetEmailRequest,
  TSetPhoneRequest,
} from '@components/pages/registration/core/registrationTypes';
import { ButtonComponent } from '@components/ui/button/button.component';
import { InputLabelComponent } from '@components/ui/input-label/input-label.component';
import { InputPhoneComponent } from '@components/ui/input-phone/input-phone.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@settings/validationSettings';
import { CONFIFM_CODE_LENGTH } from '@settings/constants';

import { NotificationService } from '@services/notification.service';
import { ProfileService } from '@services/profile.service';
import { TimerService } from '@services/timer.service';

import { environment } from '@environments/environment';
import { ButtonTextComponent } from '@components/ui/button-text/button-text.component';

@Component({
  selector: 'app-profile-contacts',
  imports: [
    ConfirmationInformationComponent,
    InputLabelComponent,
    InputPhoneComponent,
    InfoBlockComponent,
    ButtonComponent,
    RecaptchaModule,
    FormsModule,
    NgIf,
    ButtonTextComponent,
  ],
  providers: [
    { provide: 'timerPhone', useClass: TimerService },
    { provide: 'timerEmail', useClass: TimerService },
  ],
  templateUrl: './profile-contacts.component.html',
  styleUrl: './profile-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContactsComponent {
  captchaChangeEmail = viewChild<RecaptchaComponent>('captchaChangeEmail');
  captchaConfirmEmail = viewChild<RecaptchaComponent>('captchaConfirmEmail');

  captchaConfirmPhone = viewChild<RecaptchaComponent>('captchaConfirmPhone');
  captchaChangePhone = viewChild<RecaptchaComponent>('captchaChangePhone');

  key = environment.captcha_key;

  profileService = inject(ProfileService);
  notificationService = inject(NotificationService);

  emailPattern = EMAIL_PATTERN;
  phonePattern = PHONE_PATTERN;
  codeLength = CONFIFM_CODE_LENGTH;

  profileData = this.profileService.profileData;
  loading = signal(false);
  apiError = signal<string | null>(null);

  phoneConfirmed = this.profileService.phoneConfirmed;
  reSendPhoneSmsTimer!: Subscription;
  isGetPhoneCode = this.timerPhone.isCompletedTimer;
  timerPhoneValue = this.timerPhone.timerValue;

  emailConfirmed = this.profileService.emailConfirmed;
  reSendEmailSmsTimer!: Subscription;
  isGetEmailCode = this.timerEmail.isCompletedTimer;
  timerEmailValue = this.timerEmail.timerValue;

  setPhoneForm: TSetPhoneRequest = {
    phone_number: '',
    recaptcha: null,
  };

  setEmailForm: TSetEmailRequest = {
    email: '',
    recaptcha: null,
  };

  changeProfile = effect(() => {
    if (this.profileData()) {
      this.setPhoneForm.phone_number =
        this.profileData()?.phone_number.slice(2, 12) || '';
      this.setEmailForm.email = this.profileData()?.email || '';
    }
  });

  constructor(
    @Inject('timerPhone') private timerPhone: TimerService,
    @Inject('timerEmail') private timerEmail: TimerService,
    private viewport: ViewportScroller,
  ) {}

  changePhone(captchaResponse: string | null) {
    this.setPhoneForm.recaptcha = captchaResponse;

    if (this.setPhoneForm.recaptcha) {
      this.loading.set(true);

      this.profileService
        .requestChangePhone({
          phone_number: '+7' + this.setPhoneForm.phone_number,
          recaptcha: this.setPhoneForm.recaptcha,
        })
        .subscribe({
          next: () => {
            this.apiError.set(null);
            this.profileService.setPhoneConfirmed(false);
            this.captchaChangePhone()?.reset();

            if (this.reSendPhoneSmsTimer)
              this.reSendPhoneSmsTimer.unsubscribe();
            this.reSendPhoneSmsTimer = this.timerPhone
              .createTimer()
              .subscribe();

            this.loading.set(false);
          },
          error: (error: TFormattedApiError) => {
            this.apiError.set(error.formattedErrorMessage);
            this.captchaChangePhone()?.reset();

            this.loading.set(false);
            this.viewport.scrollToPosition([0, 0]);
          },
        });
    }
  }

  confirmPhone(captchaResponse: string | null, code: string) {
    this.setPhoneForm.recaptcha = captchaResponse;

    if (this.setPhoneForm.recaptcha) {
      this.loading.set(true);

      this.profileService
        .confirmChangePhone({
          confirm_code: code,
          recaptcha: this.setPhoneForm.recaptcha,
        })
        .subscribe({
          next: () => {
            this.apiError.set(null);
            this.profileService.setPhoneConfirmed(true);
            this.captchaConfirmPhone()?.reset();

            this.loading.set(false);
          },
          error: (error: TFormattedApiError) => {
            this.apiError.set(error.formattedErrorMessage);
            this.captchaConfirmPhone()?.reset();

            this.loading.set(false);
            this.viewport.scrollToPosition([0, 0]);
          },
        });
    }
  }

  changeEmail(captchaResponse: string | null) {
    this.setEmailForm.recaptcha = captchaResponse;

    if (this.setEmailForm.recaptcha) {
      this.loading.set(true);

      this.profileService.requestChangeEmail(this.setEmailForm).subscribe({
        next: () => {
          this.apiError.set(null);
          this.profileService.setEmailConfirmed(false);
          this.captchaChangeEmail()?.reset();

          if (this.reSendEmailSmsTimer) this.reSendEmailSmsTimer.unsubscribe();
          this.reSendEmailSmsTimer = this.timerEmail.createTimer().subscribe();

          this.loading.set(false);
        },
        error: (error: TFormattedApiError) => {
          this.apiError.set(error.formattedErrorMessage);
          this.captchaChangeEmail()?.reset();

          this.loading.set(false);
          this.viewport.scrollToPosition([0, 0]);
        },
      });
    }
  }

  confirmEmail(captchaResponse: string | null, code: string) {
    this.setEmailForm.recaptcha = captchaResponse;

    if (this.setEmailForm.recaptcha) {
      this.loading.set(true);

      this.profileService
        .confirmChangeEmail({
          confirm_code: code,
          recaptcha: this.setEmailForm.recaptcha,
        })
        .subscribe({
          next: () => {
            this.apiError.set(null);
            this.profileService.setEmailConfirmed(true);
            this.captchaConfirmEmail()?.reset();

            this.loading.set(false);
          },
          error: (error: TFormattedApiError) => {
            this.apiError.set(error.formattedErrorMessage);
            this.captchaConfirmEmail()?.reset();

            this.loading.set(false);
            this.viewport.scrollToPosition([0, 0]);
          },
        });
    }
  }
}
