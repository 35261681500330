<div class="main-layout" [class.without-scroll]="removeMainScroll()">
  <div class="page-content">
    @if (!isMobile()) {
      <app-header appDynamicHeader />
    }

    @if (isNotNetwork()) {
      <app-not-network />
    } @else {
      <router-outlet />
    }
  </div>

  <app-b24-crm-button class="b24-crm-button" />
  <app-pwa-ios-popup />

  <!-- todo Почему-то иногда отваливается -->
  <!-- @defer (hydrate on viewport) { -->
  @if (isMobile()) {
    <app-mobile-footer class="footer" />
  } @else {
    <app-footer class="footer" />
  }
  <!-- } -->
</div>
