import { TMenuItems } from '@models/commonTypes';
import {
  ROUTE_CONTACTS,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_PRICE_ANALYSIS,
  ROUTE_PROFILE,
} from './routes';

export const headerMenuListOpen: TMenuItems = [
  {
    id: 100,
    name: 'Аналитика цен',
    link: `/${ROUTE_PRICE_ANALYSIS}`,
  },
  {
    id: 101,
    name: 'Контакты',
    link: `/${ROUTE_CONTACTS}`,
  },
];

export const headerMenuList = [
  {
    id: 100,
    name: 'Аналитика цен',
    link: `/${ROUTE_PRICE_ANALYSIS}`,
  },
  {
    id: 101,
    name: 'Мои объявления',
    link: `/${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`,
  },
  {
    id: 102,
    name: 'Контакты',
    link: `/${ROUTE_CONTACTS}`,
  },
];
