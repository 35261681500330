import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  viewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { WA_WINDOW } from '@ng-web-apis/common';

import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_FAVOURITES,
  ROUTE_PROFILE,
  ROUTE_SEARCH,
  ROUTE_STATIC_PAGES,
} from '@settings/routes';
import { footerMenuList, footerMenuListOpen } from '@settings/footerMenuLists';
import { SearchWithButtonComponent } from '@components/ui/search/search-with-button/search-with-button.component';
import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { ContactBlockComponent } from './contact-block/contact-block.component';
import { IconButtonLinkComponent } from '@components/ui/icon-button-link/icon-button-link.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { LogoLinkComponent } from '../logo-link/logo-link.component';

import { AuthService } from '@services/auth.service';
import { ContactsService } from '@components/pages/contacts/core/contacts.service';
import { StaticPagesService } from '@components/pages/static-pages/core/static-pages.service';
import { ProfileService } from '@services/profile.service';

@Component({
  selector: 'app-footer',
  imports: [
    MenuLinkComponent,
    ButtonLinkComponent,
    IconButtonComponent,
    IconButtonLinkComponent,
    SearchWithButtonComponent,
    ContactBlockComponent,
    LogoLinkComponent,
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  search = viewChild<SearchWithButtonComponent>('footerSearch');

  authService = inject(AuthService);
  contactsService = inject(ContactsService);
  pagesService = inject(StaticPagesService);
  profileService = inject(ProfileService);
  window = inject(WA_WINDOW);
  router = inject(Router);

  contacts = this.contactsService.footerContacts;
  links = this.pagesService.pages;

  isAuth = this.authService.isAuth;
  menuList = computed(() =>
    this.isAuth() ? footerMenuList : footerMenuListOpen,
  );

  year = new Date().getFullYear();

  pagesRoute = ROUTE_STATIC_PAGES;
  authLink = ROUTE_AUTH;
  favouritesLink = `${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`;
  announcementCreateLink = ROUTE_ANNOUNCEMENT_CREATE;

  // Контактные данные
  emails = computed(() => this.contacts()?.emails || []);
  phones = computed(() => this.contacts()?.phones || []);
  socials = computed(() => this.contacts()?.socials || []);

  scrollToTop(): void {
    document.getElementById('root')?.scrollIntoView();
  }

  // Поиск по объявлениям
  searchAnnouncements(search: string) {
    void this.router.navigate(['/', ROUTE_SEARCH], {
      queryParams: { search: search || undefined },
    });

    this.search()?.clear();
  }
}
