<app-back-link [link]="authRoute" />

<form
  name="recovery-set-phone-form"
  (submit)="recoverySetPhone($event)"
  class="recovery-set-phone-form"
>
  <div class="title mb-16">Восстановление пароля</div>

  <app-label
    label="Введите номер телефона, который привязан к вашему аккаунту"
  />

  @if (!!error()) {
    <app-info-block variety="error">{{
      error()?.formattedErrorMessage || "Укажите корректный номер телефона"
    }}</app-info-block>
  }

  <app-input-phone
    name="phone-number"
    id="phone-number"
    #phone_number="ngModel"
    [(ngModel)]="recoverySetPhoneForm.phone_number"
    [required]="true"
    [pattern]="phonePattern"
    [disabled]="loading()"
    [error]="(phone_number.invalid && phone_number.touched) || !!error()"
  />

  <re-captcha
    #captcha
    (resolved)="resolved($event)"
    [siteKey]="key"
    class="recaptcha-block mt-32"
  ></re-captcha>

  <app-button
    variety="secondary"
    width="stretch"
    type="submit"
    class="submit-button mb-24"
    [disabled]="
      !!phone_number.invalid ||
      loading() ||
      recoverySetPhoneForm.recaptcha === null
    "
    >Восстановить пароль</app-button
  >
</form>
