<form
  name="formChangePassword"
  #formChangePassword="ngForm"
  (ngSubmit)="handleSubmit(formChangePassword)"
  class="profile-form"
>
  <app-info-block *ngIf="!!apiError()" variety="error">{{
    apiError() || "Неизвестная ошибка"
  }}</app-info-block>

  <hr class="top" />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Старый пароль
        <sup class="required">*</sup>
      </div>
      <div *ngIf="passwordError()" class="error">
        {{ passwordError() }}
      </div>
      <div *ngIf="password.invalid && password.touched" class="error">
        Обязательное поле
      </div>
    </div>
    <app-input-password
      name="password"
      id="password"
      #password="ngModel"
      [(ngModel)]="passwordChangeForm.password"
      placeholder="Старый пароль"
      [required]="true"
      [disabled]="loading()"
      [error]="(password.invalid && password.touched) || !!passwordError()"
      class="item-input"
    />
  </div>

  <hr />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Новый пароль
        <sup class="required">*</sup>
      </div>
      <div
        *ngIf="!!new_password.touched && !isNewPasswordValid()"
        class="error"
      >
        Введите пароль, соответствующий указанным требованиям
      </div>
    </div>
    <div class="value">
      <app-input-password
        name="new_password"
        id="new_password"
        #new_password="ngModel"
        [(ngModel)]="passwordChangeForm.new_password"
        [disabled]="loading()"
        [error]="!!new_password.touched && !isNewPasswordValid()"
        (input)="changePassword(passwordChangeForm.new_password)"
        class="item-input"
      />
      <div class="password_data">
        <div class="password_data_item">Сложность пароля:</div>
        <div
          class="password_data_item"
          [class.done]="passwordHasDifferentLetters()"
        >
          @if (!passwordHasDifferentLetters()) {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g opacity="0.8">
                <path
                  d="M12 4L4 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          } @else {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3 8.33333L6.33333 11.6667L13 5"
                stroke="#6F9305"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          Содержит прописные и строчные латинские символы
        </div>
        <div class="password_data_item" [class.done]="passwordMinLength()">
          @if (!passwordMinLength()) {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g opacity="0.8">
                <path
                  d="M12 4L4 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          } @else {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3 8.33333L6.33333 11.6667L13 5"
                stroke="#6F9305"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          Минимальное кол-во символов: 8
        </div>
        <div class="password_data_item" [class.done]="passwordHasDigits()">
          @if (!passwordHasDigits()) {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g opacity="0.8">
                <path
                  d="M12 4L4 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="#878787"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          } @else {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3 8.33333L6.33333 11.6667L13 5"
                stroke="#6F9305"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          Содержит цифры
        </div>
      </div>
    </div>
  </div>

  <hr class="middle-margin" />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Повтор пароля
        <sup class="required">*</sup>
      </div>
      <div
        *ngIf="
          !!confirm_new_password.touched &&
          passwordChangeForm.confirm_new_password !==
            passwordChangeForm.new_password
        "
        class="error"
      >
        Пароли не совпадают
      </div>
    </div>
    <app-input-password
      name="confirm_new_password"
      id="confirm_new_password"
      #confirm_new_password="ngModel"
      autocomplete="new-password"
      [(ngModel)]="passwordChangeForm.confirm_new_password"
      placeholder="Повтор пароля"
      [disabled]="loading()"
      [error]="
        !!confirm_new_password.touched &&
        passwordChangeForm.confirm_new_password !==
          passwordChangeForm.new_password
      "
      class="item-input"
    />
  </div>

  <hr />
  <div class="recaptcha-block">
    <re-captcha
      #captcha
      (resolved)="resolved($event)"
      [siteKey]="key"
    ></re-captcha>
  </div>

  <app-button
    variety="secondary"
    type="submit"
    [disabled]="
      !isNewPasswordValid() ||
      passwordChangeForm.new_password !==
        passwordChangeForm.confirm_new_password ||
      !!password.invalid ||
      passwordChangeForm.recaptcha === null
    "
    >Сохранить</app-button
  >
</form>
