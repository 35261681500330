import {
  Component,
  ChangeDetectionStrategy,
  inject,
  ElementRef,
  viewChild,
  signal,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { promoItemsFirstList, promoItemsSecondList } from './promoItemsList';
import { LogoLinkComponent } from '@components/common/logo-link/logo-link.component';
import { UIService } from '@services/ui.service';
import { PromoHoverBlockComponent } from '@components/pages/home/promo/promo-hover-block/promo-hover-block.component';

@Component({
  selector: 'app-promo',
  imports: [LogoLinkComponent, PromoHoverBlockComponent, MatIcon],
  templateUrl: './promo.component.html',
  styleUrl: './promo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoComponent {
  uiService = inject(UIService);

  itemsFist = promoItemsFirstList;
  itemsSecond = promoItemsSecondList;

  isActive = signal(false);
  isMobile = this.uiService.isMobile;
  videoPlayer = viewChild<ElementRef>('videoPlayer');

  // todo Добавить ссылку
  trialLink = '/';

  toggleVideoSize() {
    this.isActive.update((value) => !value);

    if (this.isActive()) {
      this.videoPlayer()?.nativeElement.play();
    } else {
      this.videoPlayer()?.nativeElement.pause();
    }
  }

  onVideoEnded() {
    this.isActive.set(false);
    this.videoPlayer()!.nativeElement.currentTime = 0;
  }
}
