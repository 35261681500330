import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  WA_LOCAL_STORAGE,
  WA_NAVIGATOR,
  WA_USER_AGENT,
} from '@ng-web-apis/common';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { CrossIconComponent } from '@components/ui/icons/cross-icon.component';

@Component({
  selector: 'app-pwa-ios-popup',
  imports: [NgClass, NgOptimizedImage, CrossIconComponent],
  templateUrl: './pwa-ios-popup.component.html',
  styleUrl: './pwa-ios-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaIosPopupComponent {
  userAgent = inject(WA_USER_AGENT);
  navigator = inject(WA_NAVIGATOR);
  localStorage = inject(WA_LOCAL_STORAGE);

  doNotShowPopup = signal(false);

  constructor() {
    afterNextRender(() => {
      if (this.localStorage.getItem('DisableIOSPWA')) {
        this.doNotShowPopup.set(true);
      }
    });
  }

  isIos(): boolean {
    const userAgent = this.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  isIPad(): boolean {
    return navigator.platform.startsWith('Mac') && navigator.maxTouchPoints > 4;
  }

  isInStandaloneMode(): boolean {
    return !!('standalone' in this.navigator && this.navigator.standalone);
  }

  isSafariIos(): boolean {
    return !!(
      this.isIos() &&
      this.userAgent.match(/iP(ad|hone|od).+Version\/[\d.]+.*Safari/i)
    );
  }

  closePWA() {
    this.localStorage.setItem('DisableIOSPWA', '1');
    this.doNotShowPopup.set(true);
  }
}
