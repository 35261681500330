@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title class="open-contacts-title"
      >Открытые контакты в объявлениях</app-title
    >
  </app-mobile-header>
} @else {
  <app-title> Открытые контакты в объявлениях </app-title>
}

@if (error()) {
  <app-info-block variety="error">{{
    error() || "Неизвестная ошибка"
  }}</app-info-block>
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && openContactsList().length < 1"
  emptyDescription="Список объявлений, на которых были открыты контакты, пуст"
>
  <div class="open-contacts-announcements-list">
    @for (announcement of openContactsList(); track announcement.slug) {
      <app-announcement-card
        source="open-contacts"
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [announcement]="announcement"
        [isFavouritesLoading]="isFavouritesLoading()"
        [class.disabled]="isFavouritesLoading()"
      />
    }
  </div>

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>
