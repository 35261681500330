import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';

import { ButtonComponent } from '@components/ui/button/button.component';
import { profileMenuList } from '../core/profileMenuList';
import { ButtonClipboardComponent } from '@components/ui/button-clipboard/button-clipboard.component';
import { AvatarComponent } from './avatar/avatar.component';
import {
  ROUTE_ORGANIZATION_CONTACTS,
  ROUTE_USER_CONTACTS,
} from '@settings/routes';

import { AuthService } from '@services/auth.service';
import { ProfileService } from '@services/profile.service';

@Component({
  selector: 'app-profile-sidebar',
  imports: [
    AvatarComponent,
    ButtonComponent,
    ButtonClipboardComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './profile-sidebar.component.html',
  styleUrl: './profile-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSidebarComponent {
  profile = inject(ProfileService);

  menuList = computed(() => profileMenuList(this.profile.profileData()?.type));
  userId = this.profile.profileData()?.id;

  linkToProfile = computed<string>(() =>
    this.profile.isRecycler()
      ? `${location.origin}/${ROUTE_ORGANIZATION_CONTACTS}/${this.profile.profileData()?.organization?.id}`
      : `${location.origin}/${ROUTE_USER_CONTACTS}/${this.userId}`,
  );

  constructor(private authService: AuthService) {}

  logOut() {
    this.authService.logOut();
  }
}
