<div class="main">
  <div class="icon">
    <ng-content select="[icon]"></ng-content>
  </div>
  <div>
    <div class="title">{{ title() }}</div>
    @if (description()) {
      <div class="description">{{ description() }}</div>
    }
  </div>
</div>
