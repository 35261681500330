<div
  class="pwa-install-popup-ios"
  [ngClass]="{
    'ipad-device': isIPad(),
    'safari-nav': isSafariIos(),
    hide:
      (!isIPad() && !isSafariIos()) || isInStandaloneMode() || doNotShowPopup(),
  }"
>
  <app-cross-icon class="cross" (click)="closePWA()" />
  <div class="pwa-install-popup-ios-content">
    <div class="left">
      <img
        class="appIcon"
        ngSrc="/assets/zakroma-pwa-logo.png"
        height="192"
        width="192"
        alt="Закрома"
      />
    </div>
    <div class="right">
      <div>Чтобы установить Закрома на вашем главном экране:</div>
      <div>
        1. Нажмите на меню действий
        <span>
          <img
            class="small"
            ngSrc="/assets/ios-share.png"
            height="558"
            width="446"
            alt="Поделиться"
          />
        </span>
      </div>
      <div>2. Выберите из меню 'На экран «Домой»'</div>
    </div>
  </div>
</div>
