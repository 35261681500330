import {
  ROUTE_FAVOURITES,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_OPEN_CONTACTS,
  ROUTE_PROFILE_PRICE_EDIT,
  ROUTE_PROFILE_PRIORITY,
  ROUTE_SETTINGS,
  // ROUTE_SUBSCRIPTION,
} from '@settings/routes';
import { TBaseTraderKind } from '@components/pages/registration/core/registrationTypes';

export const profileMenuList = (userType?: TBaseTraderKind) => {
  const menu = [
    {
      id: 'profile-menu-1',
      link: ROUTE_MY_ANNOUNCEMENTS,
      label: 'Мои объявления',
    },
    {
      id: 'profile-menu-priority',
      link: ROUTE_PROFILE_PRIORITY,
      label: 'Фильтрация',
    },
    {
      id: 'profile-menu-2',
      link: ROUTE_FAVOURITES,
      label: 'Избранное',
    },
    {
      id: 'profile-menu-3',
      link: ROUTE_OPEN_CONTACTS,
      label: 'Открытые контакты',
    },
    // subscription пока скрываем раздел с подпиской
    // {
    //   id: 'profile-menu-4',
    //   link: ROUTE_SUBSCRIPTION,
    //   label: 'Подписка',
    // },
    {
      id: 'profile-menu-5',
      link: ROUTE_SETTINGS,
      label: 'Настройки',
    },
  ];
  if (userType === 'recycler') {
    menu.push({
      id: 'profile-menu-6',
      link: ROUTE_PROFILE_PRICE_EDIT,
      label: 'Редактирование цен',
    });
  }
  return menu;
};
