<app-external-layout>
  <div class="full-screen-form">
    @if (!loadingStep()) {
      @switch (currentStep()) {
        @case ("set-phone-number") {
          <app-recovery-set-phone />
        }

        @case ("confirm-phone-number") {
          <app-recovery-confirm-phone />
        }

        @case ("set-password") {
          <app-recovery-set-password />
        }

        @case ("password-changed") {
          <app-recovery-password-changed />
        }

        @default {
          <app-info-block variety="error">{{
            errorCurrentStep()?.formattedErrorMessage || "Неизвестная ошибка"
          }}</app-info-block>
        }
      }

      @if (currentStep() !== "password-changed") {
        <div class="link-block">
          Нет аккаунта?
          <app-link
            [link]="registrationRoute"
            description="Зарегистрироваться"
          />
        </div>
      }
    } @else {
      <app-loader class="password-recovery-loader" />
    }
  </div>
</app-external-layout>
