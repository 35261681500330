import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';

@Component({
  selector: 'app-promo-hover-block',
  imports: [],
  templateUrl: './promo-hover-block.component.html',
  styleUrl: './promo-hover-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.backgroundImage]': 'imageUrl()',
    '[class.hovered]': 'hovered()',
    '(mouseenter)': 'this.hovered.set(true)',
    '(focusin)': 'this.hovered.set(true)',
    '(mouseleave)': 'this.hovered.set(false)',
    '(focusout)': 'this.hovered.set(false)',
  },
})
export class PromoHoverBlockComponent {
  title = input.required<string>();
  imageUrl = input.required<string>();

  description = input<string>('');

  hovered = signal(false);
}
